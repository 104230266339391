import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { cardFooterStyle } from './CardFooterStyle';

interface CardFooterProps {
  className?: string;
  plain?: boolean;
}

const useStyles = makeStyles(cardFooterStyle);

export const CardFooter: React.FC<CardFooterProps> = ({ className, plain, children, ...rest }) => {
  const classes = useStyles();
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [className!]: className !== undefined,
  });

  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};
