import React from 'react';
import classNames from 'classnames';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { productPageStyle } from './ProductPageStyle';
// https://github.com/xiaolin/react-image-gallery
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { formatWeight } from '../helpers/formatterHelper';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { CardHeader } from '../components/Card/CardHeader';
import Img from 'gatsby-image';
import { CardBody } from '../components/Card/CardBody';
import { Card } from '../components/Card/Card';
import { CardFooter } from '../components/Card/CardFooter';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { ProductNode } from '../interfaces/contentful';
import { Location } from 'history';
import { FormattedMessage, useIntl } from 'react-intl';
import { Price } from '../components/Price/Price';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(productPageStyle);

const ProductPage: React.FC<ProductPageProps> = ({ location, data }) => {
  const { productPage, relatedProducts } = data;
  const { state } = location;

  const classes = useStyles();
  const intl = useIntl();

  const images: ReactImageGalleryItem[] = [];

  if (productPage.sampleImages && productPage.sampleImages.length > 0) {
    productPage.sampleImages.forEach((sImage) => {
      images.push({
        original: sImage.file.url,
        thumbnail: sImage.fixed.src,
      });
    });
  } else {
    images.push({
      original: productPage.cover.file.url,
      thumbnail: productPage.cover.fluid.src,
    });
  }

  return (
    <div className={classNames(classes.productPage)}>
      <Helmet>
        <title>{productPage.title}</title>
      </Helmet>
      <DefaultHeader />
      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            {state && state.categoryUrl && state.categoryTitle && (
              <Link component={GatsbyLink} to={state.categoryUrl} className={classes.link}>
                <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" /> {state.categoryTitle}
              </Link>
            )}
            <Typography color="inherit">{productPage.title}</Typography>
          </Breadcrumbs>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ImageGallery showFullscreenButton={false} showPlayButton={false} startIndex={0} items={images} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h2 className={classes.title}>{productPage.title}</h2>
                <h3 className={classes.mainPrice}>
                  <FormattedMessage id="general.price" defaultMessage="Price" />: <Price product={productPage} />
                  {productPage.weight && (
                    <>
                      <br />
                      <FormattedMessage id="general.weight" defaultMessage="Weight" />:{' '}
                      {formatWeight(productPage.weight, intl.formatMessage({ id: 'general.kilogram' }))}
                    </>
                  )}
                </h3>
                {productPage.description && (
                  <div style={{ overflow: 'auto' }}>{productPage.description.description}</div>
                )}
                <br />
                <AddToCartButton product={productPage} quantity={1} />
              </Grid>
            </Grid>
          </div>

          {/*RELATED PRODUCTS*/}
          <div>
            <h2 className={classNames(classes.title, classes.textCenter)}>
              <FormattedMessage id="product.interestedInOtherProducts" defaultMessage="You may be interested in" />:
            </h2>
            <br />
            <Grid container className={classes.gridContainer} justify="center" spacing={2}>
              {relatedProducts.edges
                .filter(({ node }) => node.contentful_id !== productPage.contentful_id)
                .map(({ node: product }) => {
                  return (
                    <Grid key={product.contentful_id} item md={3} sm={4} xs={10}>
                      <Card className={classes.cardRelatedProduct}>
                        <CardHeader image>
                          <GatsbyLink to={`/products/${product.slug}`}>
                            <Img fluid={product.cover.fluid} />
                          </GatsbyLink>
                        </CardHeader>
                        <CardBody>
                          <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                            <GatsbyLink to={`/products/${product.slug}`}>{product.title}</GatsbyLink>
                          </h4>

                          <div style={{ maxHeight: '200px', overflow: 'auto' }} className={classes.cardDescription}>
                            {product.description?.description}
                          </div>
                        </CardBody>
                        <CardFooter className={classes.textCenter}>
                          <Grid container justify="space-between" alignContent="center" alignItems="center" spacing={0}>
                            <Grid item xs={12}>
                              <h4 style={{ marginBottom: '0px' }}>
                                <Price product={product} />
                              </h4>
                            </Grid>
                            <Grid item xs={12}>
                              <AddToCartButton product={product} quantity={1} size="sm" onlyIcon={true} />
                            </Grid>
                          </Grid>
                        </CardFooter>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

interface ProductPageProps {
  location: Location<{ categoryUrl?: string; categoryTitle?: string }>;
  data: {
    productPage: ProductNode;
    relatedProducts: {
      edges: [
        {
          node: ProductNode;
        },
      ];
    };
  };
}

export const productQuery = graphql`
  query($slug: String!, $locale: String!, $mainCategorySlug: String) {
    productPage: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contentful_id
      title
      slug
      price
      discountedPrice
      weight
      description {
        description
      }
      cover {
        file {
          url
        }
        fluid(maxWidth: 230, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      sampleImages {
        file {
          url
        }
        fixed(width: 100) {
          src
        }
      }
      appearsAt {
        year
        month
        desc
      }
      category {
        title
      }
    }
    relatedProducts: allContentfulProduct(
      filter: { category: { elemMatch: { slug: { eq: $mainCategorySlug } } } }
      limit: 4
    ) {
      edges {
        node {
          contentful_id
          title
          slug
          price
          discountedPrice
          description {
            description
          }
          cover {
            fluid(maxWidth: 230, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
